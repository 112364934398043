// EditableField.jsx
import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

const EditableField = ({
  product,
  field,
  value,
  placeholder,
  type = 'text',
  step,
  min,
  max,
  onUpdate,
  prefix,
  suffix,
  updateProductLocally,
  immediateUpdate = true
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleChange = (e) => {
    let newValue = e.target.value;
    
    if (type === 'number') {
      if (max !== undefined && parseFloat(newValue) > max) {
        newValue = max;
      }
      if (min !== undefined && parseFloat(newValue) < min) {
        newValue = min;
      }
    }
    
    setTempValue(newValue);
    if (onUpdate) {
      onUpdate(newValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setTempValue(value);
    }
  };

  const handleSave = async () => {
    if (!immediateUpdate) {
      setIsEditing(false);
      return;
    }

    try {
      const productRef = doc(db, "products", product.id);
      const updates = {
        [field]: type === 'number' ? parseFloat(tempValue) : tempValue
      };
      
      await updateDoc(productRef, updates);
      updateProductLocally(product.id, updates);
      setIsEditing(false);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      alert(`Failed to update ${field}. Please try again.`);
      setTempValue(value);
    }
  };

  if (isEditing) {
    return (
      <div className="flex items-center">
        {prefix && <span className="mr-1">{prefix}</span>}
        <input
          type={type}
          value={tempValue}
          onChange={handleChange}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          step={step}
          min={min}
          max={max}
          className="w-full px-2 py-1 text-sm border rounded focus:outline-none focus:border-blue-500"
          autoFocus
        />
        {suffix && <span className="ml-1">{suffix}</span>}
      </div>
    );
  }

  return (
    <div
      onClick={() => setIsEditing(true)}
      className="cursor-pointer hover:bg-gray-50 px-2 py-1 rounded"
    >
      {prefix && <span className="mr-1">{prefix}</span>}
      {value || placeholder}
      {suffix && <span className="ml-1">{suffix}</span>}
    </div>
  );
};

export default EditableField;