// Products.jsx
import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import ProductList from "./ProductList";
import CreateProducts from "../../pages/CreateProducts";
import { ChevronDown, ChevronRight, Loader } from "lucide-react";

const ITEMS_PER_PAGE = 12;

const Products = () => {
  const [view, setView] = useState("browse");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState(new Set());

  // Refs for pagination and caching
  const lastDocRef = useRef(null);
  const observerRef = useRef(null);
  const loadingRef = useRef(false);
  const categoryRef = useRef(selectedCategory);
  const dropdownRef = useRef(null);

  // Cache for storing products and scroll positions per category
  const categoryCache = useRef(new Map());

  // Click outside handler for dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Fetch categories with local storage
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const cached = localStorage.getItem("categories");
        if (cached) {
          const { data, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
            setCategories(data);
            return;
          }
        }

        const snapshot = await getDocs(collection(db, "categories"));
        const categoryList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          name: doc.data().name || "",
          parentId: doc.data().parentId || null,
          numericId: doc.data().numericId || 0,
        }));

        const sortedCategories = categoryList.sort(
          (a, b) => a.numericId - b.numericId
        );

        localStorage.setItem(
          "categories",
          JSON.stringify({
            data: sortedCategories,
            timestamp: Date.now(),
          })
        );

        setCategories(sortedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Failed to fetch categories");
      }
    };

    fetchCategories();
  }, []);

  // Category changes and caching handling
  useEffect(() => {
    if (categoryRef.current !== selectedCategory) {
      if (products.length > 0) {
        categoryCache.current.set(categoryRef.current, {
          products,
          scrollPosition: window.scrollY,
          lastDoc: lastDocRef.current,
          hasMore,
          timestamp: Date.now(),
        });
      }

      const cachedData = categoryCache.current.get(selectedCategory);
      if (cachedData) {
        setProducts(cachedData.products);
        lastDocRef.current = cachedData.lastDoc;
        setHasMore(cachedData.hasMore);
        setTimeout(() => window.scrollTo(0, cachedData.scrollPosition), 0);
      } else {
        setProducts([]);
        setHasMore(true);
        lastDocRef.current = null;
        window.scrollTo(0, 0);
        if (selectedCategory) {
          fetchProducts(true);
        }
      }

      categoryRef.current = selectedCategory;
    }
  }, [selectedCategory, hasMore]);

  // Fetch products function
  const fetchProducts = async (isInitial = false) => {
    if (loadingRef.current || !selectedCategory) return;

    try {
      isInitial ? setLoading(true) : setLoadingMore(true);
      loadingRef.current = true;
      setError(null);

      const productsRef = collection(db, "products");
      let baseQuery = query(
        productsRef,
        where("categoryId", "==", selectedCategory),
        limit(ITEMS_PER_PAGE)
      );

      if (!isInitial && lastDocRef.current) {
        baseQuery = query(
          productsRef,
          where("categoryId", "==", selectedCategory),
          startAfter(lastDocRef.current),
          limit(ITEMS_PER_PAGE)
        );
      }

      const snapshot = await getDocs(baseQuery);

      if (snapshot.empty) {
        setHasMore(false);
        if (isInitial) setProducts([]);
        return;
      }

      const newProducts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      lastDocRef.current = snapshot.docs[snapshot.docs.length - 1];

      if (isInitial) {
        setProducts(newProducts);
      } else {
        setProducts((prev) => {
          const combined = [...prev, ...newProducts];
          const seen = new Set();
          return combined.filter((product) => {
            if (seen.has(product.id)) return false;
            seen.add(product.id);
            return true;
          });
        });
      }

      setHasMore(snapshot.docs.length === ITEMS_PER_PAGE);
    } catch (error) {
      console.error("Error loading products:", error);
      setError("Failed to load products");
    } finally {
      setLoading(false);
      setLoadingMore(false);
      loadingRef.current = false;
    }
  };

  // Intersection Observer for infinite scroll
  useEffect(() => {
    if (!hasMore || loading || !selectedCategory) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loadingRef.current) {
          fetchProducts(false);
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [hasMore, loading, selectedCategory]);

  // Clean up old cache entries
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      for (const [key, value] of categoryCache.current.entries()) {
        if (now - value.timestamp > 5 * 60 * 1000) {
          categoryCache.current.delete(key);
        }
      }
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  // Product update and delete handlers
  const updateProductLocally = (productId, updates) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, ...updates } : product
      )
    );

    const cachedData = categoryCache.current.get(selectedCategory);
    if (cachedData) {
      cachedData.products = cachedData.products.map((product) =>
        product.id === productId ? { ...product, ...updates } : product
      );
      categoryCache.current.set(selectedCategory, {
        ...cachedData,
        timestamp: Date.now(),
      });
    }
  };

  const deleteProductLocally = (productId) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== productId)
    );

    const cachedData = categoryCache.current.get(selectedCategory);
    if (cachedData) {
      cachedData.products = cachedData.products.filter(
        (product) => product.id !== productId
      );
      categoryCache.current.set(selectedCategory, {
        ...cachedData,
        timestamp: Date.now(),
      });
    }
  };

  // Category selection handlers
  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(categoryId)) {
        newSet.delete(categoryId);
      } else {
        newSet.add(categoryId);
      }
      return newSet;
    });
  };

  const handleCategorySelect = (categoryId) => {
    const hasChildren = categories.some((cat) => cat.parentId === categoryId);
    if (hasChildren) {
      toggleCategory(categoryId);
    } else {
      setSelectedCategory(categoryId);
      setIsDropdownOpen(false);
    }
  };

  const getSelectedCategoryName = () => {
    if (!selectedCategory) return "Select Category";
    const category = categories.find((cat) => cat.id === selectedCategory);
    return category ? category.name : "Select Category";
  };

  // CategoryItem component
  const CategoryItem = ({ category, level = 0 }) => {
    const hasChildren = categories.some((cat) => cat.parentId === category.id);
    const isExpanded = expandedCategories.has(category.id);
    const isSelected = selectedCategory === category.id;

    if (level === 0 && category.parentId !== null) return null;

    return (
      <>
        <div
          onClick={() => handleCategorySelect(category.id)}
          className={`
            px-4 py-3 cursor-pointer transition-colors duration-150
            ${isSelected ? "bg-blue-50 text-blue-600" : "hover:bg-gray-50"}
            ${level > 0 ? "ml-4 border-l" : ""}
          `}
        >
          <div className="flex items-center gap-2">
            {hasChildren && (
              <div className="w-4 h-4">
                {isExpanded ? (
                  <ChevronDown className="w-4 h-4 text-gray-500" />
                ) : (
                  <ChevronRight className="w-4 h-4 text-gray-500" />
                )}
              </div>
            )}
            <span className={`${isSelected ? "font-medium" : ""}`}>
              {category.name}
            </span>
          </div>
        </div>

        {isExpanded &&
          categories
            .filter((cat) => cat.parentId === category.id)
            .sort((a, b) => a.numericId - b.numericId)
            .map((subCategory) => (
              <CategoryItem
                key={subCategory.id}
                category={subCategory}
                level={level + 1}
              />
            ))}
      </>
    );
  };

  // Filter products based on search
  const getFilteredProducts = () => {
    if (!searchQuery) return products;

    const query = searchQuery.toLowerCase();
    return products.filter(
      (product) => product.name?.toLowerCase().includes(query)
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Product Management</h1>
          <div className="mt-2 space-x-4">
            <button
              onClick={() => setView("browse")}
              className={`px-4 py-2 rounded transition-colors ${
                view === "browse"
                  ? "bg-primary text-white"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
            >
              Browse Products
            </button>
            <button
              onClick={() => setView("create")}
              className={`px-4 py-2 rounded transition-colors ${
                view === "create"
                  ? "bg-primary text-white"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
            >
              Create Products
            </button>
          </div>
        </div>
      </div>

      {/* Category Selection */}
      <div className="mb-6" ref={dropdownRef}>
        <div className="relative">
          <div
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="w-full flex items-center justify-between px-4 py-3 text-lg border rounded-lg bg-white hover:bg-gray-50 cursor-pointer shadow-sm"
          >
            <span className="font-medium">{getSelectedCategoryName()}</span>
            <ChevronDown
              className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${
                isDropdownOpen ? "rotate-180" : ""
              }`}
            />
          </div>

          {isDropdownOpen && (
            <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-96 overflow-y-auto">
              {categories
                .filter((cat) => !cat.parentId)
                .sort((a, b) => a.numericId - b.numericId)
                .map((category) => (
                  <CategoryItem key={category.id} category={category} />
                ))}
            </div>
          )}
        </div>
      </div>

      {/* Main Content */}
      {view === "create" ? (
        <CreateProducts
          categories={categories}
          onProductCreated={(newProduct) => {
            setProducts((prev) => [newProduct, ...prev]);
          }}
        />
      ) : (
        <>
          <ProductList
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            filteredProducts={getFilteredProducts()}
            updateProductLocally={updateProductLocally}
            deleteProductLocally={deleteProductLocally}
            loading={loading}
          />

          {/* Infinite Scroll Trigger */}
          {selectedCategory && (
            <div ref={observerRef} className="h-20 w-full">
              {loadingMore && (
                <div className="flex justify-center items-center py-8">
                  <Loader className="w-8 h-8 animate-spin text-primary" />
                </div>
              )}
            </div>
          )}

          {/* Error State */}
          {error && (
            <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
              {error}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Products;