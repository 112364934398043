import React, { useState } from "react";
import ProductRow from "./ProductRow";
import ImageViewer from "./ImageViewer";
import { Search, ChevronDown, ChevronRight } from "lucide-react";

const ProductList = ({
  searchQuery,
  setSearchQuery,
  categories,
  selectedCategory,
  setSelectedCategory,
  filteredProducts,
  updateProductLocally,
  deleteProductLocally,
  loading,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categorySearch, setCategorySearch] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const getSelectedCategoryName = () => {
    if (!selectedCategory) return "Select Category";
    const category = categories.find((cat) => cat.id === selectedCategory);
    return category ? category.name : "Select Category";
  };

  const filterCategories = (cats, searchTerm) => {
    return cats.filter((category) =>
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const CategoryItem = ({ category, level, onSelect }) => {
    const hasChildren = categories.some((cat) => cat.parentId === category.id);
    const isSelected = selectedCategory === category.id;

    return (
      <div
        onClick={() => onSelect(category.id)}
        className={`
          px-4 py-2 cursor-pointer hover:bg-gray-100 
          ${isSelected ? "bg-blue-50" : ""}
        `}
        style={{ paddingLeft: `${(level + 1) * 16}px` }}
      >
        <div className="flex items-center gap-2">
          {hasChildren ? (
            <ChevronRight className="w-4 h-4 text-gray-500" />
          ) : (
            <div className="w-4" />
          )}
          <span className={isSelected ? "font-medium" : "font-normal"}>
            {category.name}
          </span>
        </div>
      </div>
    );
  };

  const renderCategories = (parentId = null, level = 0) => {
    const filteredCats = filterCategories(categories, categorySearch);
    return filteredCats
      .filter((cat) => cat.parentId === parentId)
      .map((category) => (
        <React.Fragment key={category.id}>
          <CategoryItem
            category={category}
            level={level}
            onSelect={(id) => {
              setSelectedCategory(id);
              setIsDropdownOpen(false);
            }}
          />
          {renderCategories(category.id, level + 1)}
        </React.Fragment>
      ));
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden mt-6">
      {selectedImage && (
        <ImageViewer
          imageUrl={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}

      <div className="p-4 border-b border-gray-200">
        <div className="relative">
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        </div>
      </div>

      <div className="overflow-x-auto">
        {loading ? (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        ) : !selectedCategory ? (
          <div className="text-center py-8 text-gray-500">
            Please select a category to view products
          </div>
        ) : filteredProducts.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No products found in this category
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Images
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Original Price
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Discount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Final Price
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredProducts.map((product) => (
                <ProductRow
                  key={product.id}
                  product={product}
                  categories={categories}
                  updateProductLocally={updateProductLocally}
                  deleteProductLocally={deleteProductLocally}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ProductList;