import { useState, useEffect, useMemo } from "react";
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import CloudinaryUploadWidget from "../utils/cloudinary";

// Delete Confirmation Dialog Component remains the same
const DeleteDialog = ({ isOpen, onClose, onConfirm, title, description }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        <div
          className="fixed inset-0 bg-black bg-opacity-25"
          onClick={onClose}
        />

        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-lg">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6">
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <h3 className="text-lg font-semibold leading-6 text-gray-900">
                {title}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{description}</p>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              onClick={onConfirm}
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            >
              Delete
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CategoryManager = ({ categories, onUpdate }) => {
  const [newCategory, setNewCategory] = useState({
    name: "",
    parentId: null,
    level: 1,
    imageUrl: "",
    imageId: "",
    numericId: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [deleteDialog, setDeleteDialog] = useState({
    isOpen: false,
    categoryId: null,
    hasSubcategories: false,
  });

  const getNextAvailableId = (parentId = null) => {
    const relevantCategories = categories.filter(
      (cat) => cat.parentId === parentId
    );
    const existingIds = relevantCategories
      .map((cat) => parseInt(cat.numericId || "0"))
      .filter((id) => !isNaN(id));

    return existingIds.length > 0 ? Math.max(...existingIds) + 1 : 1;
  };

  useEffect(() => {
    if (!editMode) {
      setNewCategory((prev) => ({
        ...prev,
        numericId: getNextAvailableId(prev.parentId).toString(),
      }));
    }
  }, [newCategory.parentId, editMode, categories]);

  const sortedCategories = useMemo(() => {
    const sortByNumericId = (a, b) => {
      const aId = parseInt(a.numericId) || 0;
      const bId = parseInt(b.numericId) || 0;
      return aId - bId;
    };

    const filteredCategories = categories.filter(
      (cat) =>
        cat.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cat.numericId?.includes(searchTerm)
    );

    return [...filteredCategories].sort(sortByNumericId);
  }, [categories, searchTerm]);

  const handleImageUploadSuccess = ({ publicId, url }) => {
    setNewCategory((prev) => ({
      ...prev,
      imageUrl: url,
      imageId: publicId,
    }));
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    if (!newCategory.name.trim()) {
      setError("Category name is required");
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      const categoryQuery = query(
        collection(db, "categories"),
        where("name", "==", newCategory.name.trim()),
        where("parentId", "==", newCategory.parentId),
        where("level", "==", newCategory.level)
      );

      const existingCategories = await getDocs(categoryQuery);
      if (!existingCategories.empty && !editMode) {
        setError("A category with this name already exists at this level");
        setIsSubmitting(false);
        return;
      }

      // Create base category data with required fields
      const categoryData = {
        name: newCategory.name.trim(),
        numericId:
          newCategory.numericId ||
          getNextAvailableId(newCategory.parentId).toString(),
      };

      if (editMode && selectedCategoryId) {
        const docRef = doc(db, "categories", selectedCategoryId);
        const updateData = {
          ...categoryData,
          updatedAt: new Date(),
        };

        // Only add image fields if they exist, otherwise explicitly remove them
        if (newCategory.imageUrl && newCategory.imageId) {
          updateData.imageUrl = newCategory.imageUrl;
          updateData.imageId = newCategory.imageId;
        } else {
          updateData.imageUrl = null;
          updateData.imageId = null;
        }

        await updateDoc(docRef, updateData);
      } else {
        const newData = {
          ...categoryData,
          parentId: newCategory.parentId,
          level: newCategory.level,
          createdAt: new Date(),
        };

        // Only add image fields if they exist for new categories
        if (newCategory.imageUrl && newCategory.imageId) {
          newData.imageUrl = newCategory.imageUrl;
          newData.imageId = newCategory.imageId;
        }

        await addDoc(collection(db, "categories"), newData);
      }

      resetForm();
      onUpdate();
    } catch (error) {
      console.error("Error with category:", error);
      setError("Failed to save category. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenDeleteDialog = (categoryId) => {
    const subcategories = categories.filter(
      (cat) => cat.parentId === categoryId
    );
    setDeleteDialog({
      isOpen: true,
      categoryId,
      hasSubcategories: subcategories.length > 0,
    });
  };

  const handleDeleteCategory = async () => {
    const categoryId = deleteDialog.categoryId;
    try {
      await deleteDoc(doc(db, "categories", categoryId));

      if (deleteDialog.hasSubcategories) {
        const subcategories = categories.filter(
          (cat) => cat.parentId === categoryId
        );
        for (const subcat of subcategories) {
          await deleteDoc(doc(db, "categories", subcat.id));
        }
      }

      onUpdate();
      setDeleteDialog({
        isOpen: false,
        categoryId: null,
        hasSubcategories: false,
      });
    } catch (error) {
      console.error("Error deleting category:", error);
      setError("Failed to delete category. Please try again.");
    }
  };

  const handleEdit = (category) => {
    setEditMode(true);
    setSelectedCategoryId(category.id);
    setNewCategory({
      name: category.name,
      parentId: category.parentId,
      level: category.level,
      imageUrl: category.imageUrl || "",
      imageId: category.imageId || "",
      numericId: category.numericId || "",
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const addSubcategory = (parentCategory) => {
    if (parentCategory.level >= 3) {
      setError("Maximum category depth reached (3 levels)");
      return;
    }

    resetForm();
    const nextId = getNextAvailableId(parentCategory.id);
    setNewCategory({
      name: "",
      parentId: parentCategory.id,
      level: parentCategory.level + 1,
      imageUrl: "",
      imageId: "",
      numericId: nextId.toString(),
    });
  };

  const resetForm = () => {
    setEditMode(false);
    setSelectedCategoryId(null);
    setNewCategory({
      name: "",
      parentId: null,
      level: 1,
      imageUrl: "",
      imageId: "",
      numericId: getNextAvailableId(null).toString(),
    });
    setError("");
  };

  const toggleExpand = (categoryId) => {
    setExpandedCategories((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(categoryId)) {
        newSet.delete(categoryId);
      } else {
        newSet.add(categoryId);
      }
      return newSet;
    });
  };

  const CategoryItem = ({ category, depth = 0 }) => {
    const hasSubcategories = sortedCategories.some(
      (cat) => cat.parentId === category.id
    );
    const isExpanded = expandedCategories.has(category.id);

    return (
      <div className="w-full">
        <div
          className={`${
            depth > 0 ? "ml-8" : ""
          } bg-white border border-gray-200 rounded-lg`}
        >
          <div className="flex items-center gap-4 p-3">
            {hasSubcategories && (
              <button
                onClick={() => toggleExpand(category.id)}
                className="text-gray-500 hover:text-gray-700 p-1"
              >
                {isExpanded ? "▼" : "▶"}
              </button>
            )}

            {category.imageUrl ? (
              <img
                src={category.imageUrl}
                alt={category.name}
                className="w-12 h-12 object-cover rounded"
              />
            ) : (
              <div className="w-12 h-12 bg-gray-100 rounded flex items-center justify-center text-gray-400 text-xs">
                No Image
              </div>
            )}

            <div className="flex-1 grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
              <div className="font-medium text-gray-900">{category.name}</div>
              <div className="text-gray-600">{category.numericId || "-"}</div>
              <span className="text-sm text-gray-500">
                Level {category.level}
              </span>
            </div>

            <div className="flex gap-2">
              {category.level < 3 && (
                <button
                  onClick={() => addSubcategory(category)}
                  className="px-3 py-1 bg-emerald-500 text-white rounded text-sm hover:bg-emerald-600 transition-colors"
                >
                  Add Sub
                </button>
              )}
              <button
                onClick={() => handleEdit(category)}
                className="px-3 py-1 bg-gray-100 text-gray-700 rounded text-sm hover:bg-gray-200 transition-colors"
              >
                Edit
              </button>
              <button
                onClick={() => handleOpenDeleteDialog(category.id)}
                className="px-3 py-1 bg-red-100 text-red-600 rounded text-sm hover:bg-red-200 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>

          {isExpanded && hasSubcategories && (
            <div className="ml-8 pl-4 border-l-2 border-gray-200">
              {sortedCategories
                .filter((cat) => cat.parentId === category.id)
                .map((subcategory) => (
                  <CategoryItem
                    key={subcategory.id}
                    category={subcategory}
                    depth={depth + 1}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <DeleteDialog
        isOpen={deleteDialog.isOpen}
        onClose={() =>
          setDeleteDialog({
            isOpen: false,
            categoryId: null,
            hasSubcategories: false,
          })
        }
        onConfirm={handleDeleteCategory}
        title="Delete Category"
        description={
          deleteDialog.hasSubcategories
            ? "This will delete the category and all its subcategories. This action cannot be undone."
            : "Are you sure you want to delete this category? This action cannot be undone."
        }
      />

      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        <h2 className="text-2xl font-semibold">
          {editMode
            ? "Edit Category"
            : newCategory.parentId
            ? `Add Subcategory (Level ${newCategory.level})`
            : "Category Management"}
        </h2>

        <div className="w-full md:w-72">
          <input
            type="text"
            placeholder="Search categories..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-500 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <form onSubmit={handleAddCategory} className="mb-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium text-gray-700">
              Category Name
            </label>
            <input
              type="text"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              placeholder="Enter category name"
              disabled={isSubmitting}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium text-gray-700">
              ID Number
            </label>
            <input
              type="number"
              value={newCategory.numericId}
              onChange={(e) =>
                setNewCategory({ ...newCategory, numericId: e.target.value })
              }
              placeholder="Enter ID number"
              min="1"
              disabled={isSubmitting}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium text-gray-700">
              Category Image
            </label>
            <div className="flex items-center gap-3">
              <CloudinaryUploadWidget
                onUploadSuccess={handleImageUploadSuccess}
              />

              {newCategory.imageUrl && (
                <div className="relative w-12 h-12">
                  <img
                    src={newCategory.imageUrl}
                    alt="Preview"
                    className="w-full h-full object-cover rounded"
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setNewCategory({
                        ...newCategory,
                        imageUrl: "",
                        imageId: "",
                      })
                    }
                    className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full text-xs px-2 py-1 hover:bg-red-600"
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex gap-3 mt-6">
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 bg-blue-600 text-white rounded font-medium hover:bg-blue-700 transition-colors disabled:bg-blue-300 disabled:cursor-not-allowed"
          >
            {isSubmitting
              ? "Saving..."
              : editMode
              ? "Update Category"
              : `Add ${newCategory.parentId ? "Subcategory" : "Category"}`}
          </button>

          {(editMode || newCategory.parentId) && (
            <button
              type="button"
              onClick={resetForm}
              className="px-4 py-2 border border-gray-300 text-gray-600 rounded font-medium hover:bg-gray-50 transition-colors"
            >
              Back to main categories
            </button>
          )}
        </div>
      </form>

      <div className="mt-8">
        <h3 className="text-xl font-medium mb-4">Categories</h3>
        <div className="flex flex-col gap-3">
          {sortedCategories
            .filter((cat) => !cat.parentId)
            .map((category) => (
              <CategoryItem key={category.id} category={category} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryManager;
