import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';

const CategoryItem = ({ category, level = 0, onSelect, categories, isDropdownOpen }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = categories.some(cat => cat.parentId === category.id);
  const isTopLevel = !category.parentId;
  
  const childCategories = categories.filter(cat => cat.parentId === category.id);
  
  const handleClick = (e) => {
    e.stopPropagation();
    if (hasChildren) {
      setIsExpanded(!isExpanded);
    } else if (!isTopLevel) {
      onSelect(category.id);
    }
  };

  const levelIndent = level * 16;

  return (
    <div className="w-full">
      <div
        onClick={handleClick}
        className={`
          w-full px-4 py-2 flex items-center
          ${isTopLevel ? 'bg-gray-50 font-semibold text-gray-700' : 'hover:bg-gray-50 cursor-pointer'}
          ${level > 0 ? 'border-l border-gray-200' : ''}
          transition-colors duration-150
        `}
        style={{ paddingLeft: `${levelIndent + 16}px` }}
      >
        <div className="flex items-center gap-2 flex-1">
          {hasChildren && (
            <span className="text-gray-400">
              {isExpanded ? (
                <ChevronDown className="h-4 w-4" />
              ) : (
                <ChevronRight className="h-4 w-4" />
              )}
            </span>
          )}
          {!hasChildren && level > 0 && <span className="w-4" />}
          <span className={`${isTopLevel ? '' : 'text-gray-600'}`}>
            {category.name}
          </span>
          {isTopLevel && (
            <span className="ml-2 text-xs text-gray-400">
              ({childCategories.length} subcategories)
            </span>
          )}
        </div>
      </div>
      
      {isDropdownOpen && isExpanded && hasChildren && (
        <div className="w-full">
          {categories
            .filter(cat => cat.parentId === category.id)
            .sort((a, b) => a.numericId - b.numericId)
            .map(subCategory => (
              <CategoryItem
                key={subCategory.id}
                category={subCategory}
                level={level + 1}
                onSelect={onSelect}
                categories={categories}
                isDropdownOpen={isDropdownOpen}
              />
            ))}
        </div>
      )}
    </div>
  );
};

const CategoryDropdown = ({ 
  categories, 
  selectedCategory, 
  onSelectCategory, 
  isOpen, 
  onToggleDropdown 
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const getSelectedCategoryPath = () => {
    if (!selectedCategory) return 'Select Category';
    
    const path = [];
    let currentCat = categories.find(cat => cat.id === selectedCategory);
    
    while (currentCat) {
      path.unshift(currentCat.name);
      currentCat = categories.find(cat => cat.id === currentCat.parentId);
    }
    
    return path.join(' > ');
  };

  const filterCategories = (cats) => {
    if (!searchTerm) return cats;
    
    const normalizedSearch = searchTerm.toLowerCase();
    return cats.filter(category => 
      category.name.toLowerCase().includes(normalizedSearch)
    );
  };

  const topLevelCategories = categories
    .filter(cat => !cat.parentId)
    .sort((a, b) => a.numericId - b.numericId);

  return (
    <div className="relative w-full">
      <button
        onClick={onToggleDropdown}
        className="w-full flex items-center justify-between px-4 py-3 bg-white border rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors duration-150"
      >
        <span className="text-gray-700 font-medium truncate">
          {getSelectedCategoryPath()}
        </span>
        <ChevronDown 
          className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>

      {isOpen && (
        <div className="absolute z-50 w-full mt-2 bg-white border rounded-lg shadow-lg">
          <div className="p-3 border-b">
            <input
              type="text"
              placeholder="Search categories..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          
          <div className="max-h-[400px] overflow-y-auto">
            {searchTerm ? (
              filterCategories(categories).map(category => (
                <CategoryItem
                  key={category.id}
                  category={category}
                  onSelect={onSelectCategory}
                  categories={categories}
                  isDropdownOpen={isOpen}
                />
              ))
            ) : (
              topLevelCategories.map(category => (
                <CategoryItem
                  key={category.id}
                  category={category}
                  onSelect={onSelectCategory}
                  categories={categories}
                  isDropdownOpen={isOpen}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryDropdown;