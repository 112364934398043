import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const menus = [
    {
      title: 'Products',
      description: 'Manage your product catalog',
      path: '/products',
      icon: '📦',
      stats: 'Manage and organize your store products'
    },
    {
      title: 'Product Descriptions',
      description: 'Manage detailed product specifications',
      path: '/product-descriptions',
      icon: '📝',
      stats: 'Edit and update product details and specifications'
    },
    {
      title: 'Categories',
      description: 'Organize your products with categories',
      path: '/categories',
      icon: '🗂️',
      stats: 'Create and manage product categories'
    },
    {
      title: 'Queries',
      description: 'View and manage queries',
      path: '/queries',
      icon: '🛍️',
      stats: 'Track and process customer queries'
    },
    {
      title: 'Sequence',
      description: 'View and manage Sequence',
      path: '/order',
      icon: '↕️',
      stats: 'Track and process product Sequence'
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold mb-2">Welcome to Admin Dashboard</h1>
        <p className="text-gray-600">Manage your store from one central location</p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
        {menus.map((menu) => (
          <Link
            key={menu.path}
            to={menu.path}
            className="group p-6 bg-white rounded-lg shadow hover:shadow-lg transition-all duration-300"
          >
            <div className="flex items-center mb-4">
              <div className="text-3xl group-hover:scale-110 transition-transform duration-300">
                {menu.icon}
              </div>
              <div className="ml-4">
                <h2 className="text-xl font-semibold mb-1">{menu.title}</h2>
                <p className="text-gray-600 text-sm">{menu.description}</p>
              </div>
            </div>
            <div className="pt-4 border-t border-gray-100">
              <p className="text-gray-500 text-sm">{menu.stats}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;