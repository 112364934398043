// CloudinaryUploadWidget.js
import React, { useEffect, useRef } from 'react';

const CloudinaryUploadWidget = ({ onUploadSuccess, disabled }) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  const scriptRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Check if script already exists
      const existingScript = document.querySelector('script[src="https://upload-widget.cloudinary.com/global/all.js"]');
      
      if (!existingScript) {
        scriptRef.current = document.createElement('script');
        scriptRef.current.src = 'https://upload-widget.cloudinary.com/global/all.js';
        scriptRef.current.async = true;
        document.body.appendChild(scriptRef.current);
      } else {
        scriptRef.current = existingScript;
      }

      const initWidget = () => {
        if (window.cloudinary) {
          cloudinaryRef.current = window.cloudinary;
          widgetRef.current = cloudinaryRef.current.createUploadWidget(
            {
              cloudName: 'dpwb1gep6',
              uploadPreset: 'default',
              sources: ['local'],
              multiple: false,
              maxFiles: 1
            },
            function(error, result) {
              if (!error && result && result.event === "success") {
                onUploadSuccess({
                  publicId: result.info.public_id,
                  url: result.info.secure_url
                });
                // Close widget after successful upload
                widgetRef.current.close();
              }
            }
          );
        }
      };

      if (existingScript) {
        initWidget();
      } else {
        scriptRef.current.onload = initWidget;
      }
    }

    return () => {
      // Only remove the script if we added it
      if (scriptRef.current && !document.querySelector('script[src="https://upload-widget.cloudinary.com/global/all.js"]')) {
        document.body.removeChild(scriptRef.current);
      }
      // Destroy widget if it exists
      if (widgetRef.current) {
        widgetRef.current.destroy();
      }
    };
  }, [onUploadSuccess]);

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={(event) => {
        event.preventDefault();
        if (widgetRef.current) {
          widgetRef.current.open();
        }
      }}
      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Upload Image
    </button>
  );
};

export default CloudinaryUploadWidget;

