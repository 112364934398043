import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../config/firebase";
import {
  ChevronDown,
  ChevronUp,
  Save,
  Edit,
  Search,
  Plus,
  Trash,
  Loader,
  GripVertical,
} from "lucide-react";
import CategoryDropdown from "./CatDropdown";

const ITEMS_PER_PAGE = 12;

// Description Editor Component
const ProductDescriptionEditor = ({ product, updateProductLocally }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fields, setFields] = useState(() => {
    return Array.isArray(product.description)
      ? product.description.map((field) => ({
          ...field,
          id: field.id || crypto.randomUUID(),
        }))
      : [];
  });

  const addField = () => {
    setFields([
      ...fields,
      {
        id: crypto.randomUUID(),
        key: "",
        value: "",
        order: fields.length,
      },
    ]);
  };

  const removeField = (index) => {
    setFields(
      fields
        .filter((_, i) => i !== index)
        .map((field, i) => ({ ...field, order: i }))
    );
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
    e.currentTarget.style.opacity = "0.5";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.style.borderTop = "2px solid #3b82f6";
  };

  const handleDragLeave = (e) => {
    e.currentTarget.style.borderTop = "";
  };

  const handleDragEnd = (e) => {
    e.currentTarget.style.opacity = "1";
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    e.currentTarget.style.borderTop = "";

    const dragIndex = Number(e.dataTransfer.getData("text/plain"));
    if (dragIndex === dropIndex) return;

    const newFields = [...fields];
    const [draggedField] = newFields.splice(dragIndex, 1);
    newFields.splice(dropIndex, 0, draggedField);

    setFields(newFields.map((field, i) => ({ ...field, order: i })));
  };

  const updateField = (index, key, value) => {
    const newFields = [...fields];
    newFields[index] = { ...newFields[index], [key]: value };
    setFields(newFields);
  };

  const handleSave = async () => {
    try {
      const validFields = fields
        .filter((field) => field.key.trim() !== "")
        .map((field, index) => ({
          id: field.id,
          key: field.key.trim(),
          value: field.value.trim(),
          order: index,
        }));

      const updates = { description: validFields };
      await updateDoc(doc(db, "products", product.id), updates);
      updateProductLocally(product.id, updates);
      setIsEditing(false);
    } catch (err) {
      console.error("Error updating description:", err);
      alert("Failed to update description");
    }
  };

  return (
    <div className="mt-4 p-4 bg-white rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Product Description</h3>
        <div className="flex gap-2">
          {isEditing ? (
            <>
              <button
                onClick={addField}
                className="flex items-center gap-1 text-green-600 hover:text-green-800"
              >
                <Plus size={16} />
                <span>Add Field</span>
              </button>
              <button
                onClick={handleSave}
                className="flex items-center gap-1 text-blue-600 hover:text-blue-800"
              >
                <Save size={16} />
                <span>Save</span>
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="flex items-center gap-1 text-blue-600 hover:text-blue-800"
            >
              <Edit size={16} />
              <span>Edit</span>
            </button>
          )}
        </div>
      </div>

      <div className="space-y-4">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className={`flex gap-4 items-start ${
              isEditing
                ? "border border-transparent hover:border-gray-200 rounded-lg p-2"
                : ""
            }`}
            draggable={isEditing}
            onDragStart={(e) => handleDragStart(e, index)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, index)}
          >
            {isEditing ? (
              <>
                <div className="cursor-move text-gray-400 hover:text-gray-600">
                  <GripVertical size={16} />
                </div>
                <div className="flex-1 grid grid-cols-2 gap-4">
                  <div className="relative">
                    <input
                      type="text"
                      value={field.key}
                      onChange={(e) =>
                        updateField(index, "key", e.target.value)
                      }
                      placeholder="Field name"
                      className="w-full px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                    <span className="absolute right-2 top-2 text-xs text-gray-400">
                      {index + 1}
                    </span>
                  </div>
                  <input
                    type="text"
                    value={field.value}
                    onChange={(e) =>
                      updateField(index, "value", e.target.value)
                    }
                    placeholder="Field value"
                    className="px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <button
                  onClick={() => removeField(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash size={16} />
                </button>
              </>
            ) : (
              <div className="flex-1 grid grid-cols-2 gap-4">
                <span className="text-gray-600 font-medium">{field.key}</span>
                <span className="text-gray-900">{field.value || "-"}</span>
              </div>
            )}
          </div>
        ))}

        {fields.length === 0 && !isEditing && (
          <div className="text-center text-gray-500 py-4">
            No description fields added yet
          </div>
        )}
      </div>
    </div>
  );
};

// Main ProductDescription Component
const ProductDescription = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Refs for pagination and caching
  const lastDocRef = useRef(null);
  const observerRef = useRef(null);
  const loadingRef = useRef(false);
  const categoryRef = useRef(selectedCategory);
  const categoryCache = useRef(new Map());

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const cached = localStorage.getItem("categories");
        if (cached) {
          const { data, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
            setCategories(data);
            return;
          }
        }

        const snapshot = await getDocs(collection(db, "categories"));
        const categoryList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          name: doc.data().name || "",
          parentId: doc.data().parentId || null,
          numericId: doc.data().numericId || 0,
        }));

        const sortedCategories = categoryList.sort(
          (a, b) => a.numericId - b.numericId
        );

        localStorage.setItem(
          "categories",
          JSON.stringify({
            data: sortedCategories,
            timestamp: Date.now(),
          })
        );

        setCategories(sortedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Failed to fetch categories");
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (categoryRef.current !== selectedCategory) {
      if (products.length > 0) {
        categoryCache.current.set(categoryRef.current, {
          products,
          scrollPosition: window.scrollY,
          lastDoc: lastDocRef.current,
          hasMore,
          timestamp: Date.now(),
        });
      }

      const cachedData = categoryCache.current.get(selectedCategory);
      if (cachedData) {
        setProducts(cachedData.products);
        lastDocRef.current = cachedData.lastDoc;
        setHasMore(cachedData.hasMore);
        setTimeout(() => window.scrollTo(0, cachedData.scrollPosition), 0);
      } else {
        setProducts([]);
        setHasMore(true);
        lastDocRef.current = null;
        window.scrollTo(0, 0);
        if (selectedCategory) {
          fetchProducts(true);
        }
      }

      categoryRef.current = selectedCategory;
    }
  }, [selectedCategory, hasMore]);

  const fetchProducts = async (isInitial = false) => {
    if (loadingRef.current || !selectedCategory) return;

    try {
      isInitial ? setLoading(true) : setLoadingMore(true);
      loadingRef.current = true;
      setError(null);

      let baseQuery = query(
        collection(db, "products"),
        where("categoryId", "==", selectedCategory),
        limit(ITEMS_PER_PAGE)
      );

      if (!isInitial && lastDocRef.current) {
        baseQuery = query(
          collection(db, "products"),
          where("categoryId", "==", selectedCategory),
          startAfter(lastDocRef.current),
          limit(ITEMS_PER_PAGE)
        );
      }

      const snapshot = await getDocs(baseQuery);

      if (snapshot.empty) {
        setHasMore(false);
        if (isInitial) setProducts([]);
        return;
      }

      const newProducts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      lastDocRef.current = snapshot.docs[snapshot.docs.length - 1];

      setProducts((prev) => {
        if (isInitial) return newProducts;
        const combined = [...prev, ...newProducts];
        const seen = new Set();
        return combined.filter((product) => {
          if (seen.has(product.id)) return false;
          seen.add(product.id);
          return true;
        });
      });

      setHasMore(snapshot.docs.length === ITEMS_PER_PAGE);
    } catch (error) {
      console.error("Error loading products:", error);
      setError("Failed to load products");
    } finally {
      setLoading(false);
      setLoadingMore(false);
      loadingRef.current = false;
    }
  };

  useEffect(() => {
    if (!hasMore || loading || !selectedCategory) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loadingRef.current) {
          fetchProducts(false);
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [hasMore, loading, selectedCategory]);

  const getCategoryPath = (categoryId) => {
    if (!categoryId) return "No Category";
    const path = [];
    let currentCat = categories.find((cat) => cat.id === categoryId);

    while (currentCat) {
      path.unshift(currentCat.name);
      if (!currentCat.parentId) break;
      currentCat = categories.find((cat) => cat.id === currentCat.parentId);
    }

    return path.length > 0 ? path.join(" > ") : "No Category";
  };

  const updateProductLocally = (productId, updates) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, ...updates } : product
      )
    );

    const cachedData = categoryCache.current.get(selectedCategory);
    if (cachedData) {
      cachedData.products = cachedData.products.map((product) =>
        product.id === productId ? { ...product, ...updates } : product
      );
      categoryCache.current.set(selectedCategory, {
        ...cachedData,
        timestamp: Date.now(),
      });
    }
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  const getFilteredProducts = () => {
    if (!searchQuery) return products;

    const query = searchQuery.toLowerCase();
    return products.filter(
      (product) =>
        product.name?.toLowerCase().includes(query) ||
        getCategoryPath(product.categoryId).toLowerCase().includes(query)
    );
  };

  const filteredProducts = getFilteredProducts();

  return (
    <div className="container mx-auto p-4">
      <div className="mb-6">
        <div className="flex items-center space-x-4 mb-4">
          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search products by name or category..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <CategoryDropdown
          categories={categories}
          selectedCategory={selectedCategory}
          onSelectCategory={handleCategorySelect}
          isOpen={isDropdownOpen}
          onToggleDropdown={() => setIsDropdownOpen(!isDropdownOpen)}
        />
      </div>

      {/* Product List */}
      <div className="grid grid-cols-1 gap-4">
        {filteredProducts.map((product) => (
          <div key={product.id} className="bg-white p-4 rounded-lg shadow">
            <div className="flex gap-4">
              {product.images?.[0]?.url && (
                <div className="flex-shrink-0">
                  <img
                    src={product.images[0].url}
                    alt={product.name || "Product"}
                    className="w-24 h-24 object-cover rounded"
                  />
                </div>
              )}
              <div className="flex-grow">
                <div className="flex justify-between items-center mb-2">
                  <div>
                    <h3 className="text-lg font-semibold">
                      {product.name || "Unnamed Product"}
                    </h3>
                    <p className="text-sm text-gray-600">
                      {getCategoryPath(product.categoryId)}
                    </p>
                  </div>
                  <button
                    onClick={() =>
                      setSelectedProduct(
                        selectedProduct?.id === product.id ? null : product
                      )
                    }
                    className="text-blue-600 hover:text-blue-800"
                  >
                    {selectedProduct?.id === product.id ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </button>
                </div>
              </div>
            </div>

            {selectedProduct?.id === product.id && (
              <ProductDescriptionEditor
                product={product}
                updateProductLocally={updateProductLocally}
              />
            )}
          </div>
        ))}
      </div>

      {/* Loading States and Messages */}
      {loading ? (
        <div className="flex justify-center items-center py-12">
          <div className="relative">
            <Loader className="h-8 w-8 animate-spin text-primary" />
            <div className="mt-2 text-sm text-gray-500">
              Loading products...
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* Infinite Scroll Trigger */}
          {hasMore && selectedCategory && (
            <div ref={observerRef} className="flex justify-center py-8">
              {loadingMore && (
                <div className="flex flex-col items-center">
                  <div className="relative">
                    <div className="animate-ping absolute w-8 h-8 rounded-full bg-primary opacity-20" />
                    <Loader className="relative h-8 w-8 animate-spin text-primary" />
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    Loading more products...
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Empty States */}
          {!selectedCategory && (
            <div className="flex flex-col items-center justify-center py-12 bg-white rounded-lg shadow">
              <div className="text-gray-400 mb-4">
                <Search className="w-16 h-16" />
              </div>
              <div className="text-xl font-medium text-gray-700 mb-2">
                Select a Category
              </div>
              <div className="text-gray-500 text-center max-w-md">
                Please choose a category from the dropdown above to view
                products
              </div>
            </div>
          )}

          {!loading &&
            products.length === 0 &&
            selectedCategory &&
            !searchQuery && (
              <div className="flex flex-col items-center justify-center py-12 bg-white rounded-lg shadow">
                <div className="text-gray-400 mb-2">
                  <Loader className="w-16 h-16" />
                </div>
                <div className="text-lg text-gray-700 font-medium mb-1">
                  No Products Found
                </div>
                <div className="text-gray-500">
                  There are no products in this category yet
                </div>
              </div>
            )}

          {selectedCategory && filteredProducts.length === 0 && searchQuery && (
            <div className="flex flex-col items-center justify-center py-12 bg-white rounded-lg shadow">
              <div className="text-gray-400 mb-2">
                <Search className="w-16 h-16" />
              </div>
              <div className="text-lg text-gray-700 font-medium mb-1">
                No Matching Products
              </div>
              <div className="text-gray-500">
                No products found matching "{searchQuery}"
              </div>
            </div>
          )}
        </>
      )}

      {/* Error State */}
      {error && (
        <div className="fixed bottom-4 right-4 bg-red-50 border border-red-200 text-red-700 px-6 py-4 rounded-lg shadow-lg max-w-md">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">{error}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDescription;
