// ProductImages.jsx
import React, { useState } from 'react';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Check, Loader, Trash2, Upload } from 'lucide-react';
import ImageViewer from './ImageViewer';

const ProductImages = ({ product, updateProductLocally }) => {
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    setIsImageUploading(true);
    setUploadProgress(0);

    const uploadedImages = [];
    const errors = [];

    for (let i = 0; i < files.length; i++) {
      try {
        const formData = new FormData();
        formData.append("file", files[i]);
        formData.append("upload_preset", "default");
        formData.append("cloud_name", "dpwb1gep6");

        const response = await fetch(
          `https://api.cloudinary.com/v1_1/dpwb1gep6/image/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();

        if (data.secure_url) {
          uploadedImages.push({
            id: data.public_id,
            publicId: data.public_id,
            url: data.secure_url,
            isMain: false,
            uploadedAt: new Date().toISOString(),
          });
        } else {
          errors.push(`Failed to upload ${files[i].name}`);
        }

        setUploadProgress(Math.round(((i + 1) / files.length) * 100));
      } catch (error) {
        errors.push(`Error uploading ${files[i].name}: ${error.message}`);
      }
    }

    if (uploadedImages.length > 0) {
      if (!product.images || product.images.length === 0) {
        uploadedImages[0].isMain = true;
      }

      const newImages = [...(product.images || []), ...uploadedImages];
      try {
        const productRef = doc(db, "products", product.id);
        await updateDoc(productRef, { images: newImages });
        updateProductLocally(product.id, { images: newImages });
      } catch (error) {
        console.error("Error updating images:", error);
        alert("Failed to update images. Please try again.");
      }
    }

    if (errors.length > 0) {
      alert(`Some images failed to upload:\n${errors.join("\n")}`);
    }

    setIsImageUploading(false);
    setUploadProgress(0);
  };

  const handleSetMainImage = async (imageId) => {
    try {
      const updatedImages = product.images.map((img) => ({
        ...img,
        isMain: img.id === imageId,
      }));

      const productRef = doc(db, "products", product.id);
      await updateDoc(productRef, { images: updatedImages });
      updateProductLocally(product.id, { images: updatedImages });
    } catch (error) {
      console.error("Error setting main image:", error);
      alert("Failed to set main image. Please try again.");
    }
  };

  const handleDeleteImage = async (imageToDelete) => {
    if (product.images.length <= 1) {
      alert("Cannot delete the last remaining image.");
      return;
    }

    if (!window.confirm("Are you sure you want to delete this image?")) {
      return;
    }

    try {
      let updatedImages = product.images.filter(img => img.id !== imageToDelete.id);

      if (imageToDelete.isMain && updatedImages.length > 0) {
        updatedImages = updatedImages.map((img, index) => ({
          ...img,
          isMain: index === 0
        }));
      }

      const productRef = doc(db, "products", product.id);
      await updateDoc(productRef, { images: updatedImages });
      updateProductLocally(product.id, { images: updatedImages });

      if (selectedImage && selectedImage.id === imageToDelete.id) {
        setIsImageViewerOpen(false);
        setSelectedImage(null);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");
    }
  };

  return (
    <div className="space-y-4">
      {isImageViewerOpen && selectedImage && (
        <ImageViewer
          imageUrl={selectedImage.url}
          onClose={() => {
            setIsImageViewerOpen(false);
            setSelectedImage(null);
          }}
        />
      )}

      <div className="flex flex-wrap gap-2">
        {product.images &&
          product.images.map((image, index) => (
            <div key={image.publicId} className="relative group">
              <img
                src={image.url}
                alt={`Product ${index + 1}`}
                className="w-16 h-16 object-cover rounded cursor-pointer hover:opacity-75 transition-opacity"
                onClick={() => {
                  setSelectedImage(image);
                  setIsImageViewerOpen(true);
                }}
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-opacity rounded flex items-center justify-center opacity-0 group-hover:opacity-100">
                <div className="flex space-x-2">
                  {!image.isMain && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSetMainImage(image.id);
                      }}
                      className="p-1 bg-blue-500 rounded-full text-white hover:bg-blue-600"
                      title="Set as main image"
                    >
                      <Check size={12} />
                    </button>
                  )}
                  {product.images.length > 1 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteImage(image);
                      }}
                      className="p-1 bg-red-500 rounded-full text-white hover:bg-red-600"
                      title="Delete image"
                    >
                      <Trash2 size={12} />
                    </button>
                  )}
                </div>
              </div>
              {image.isMain && (
                <span className="absolute -top-1 -right-1 bg-green-500 text-white px-1 rounded-full text-xs">
                  Main
                </span>
              )}
            </div>
          ))}
      </div>

      <div className="relative">
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleImageUpload}
          className="hidden"
          id={`image-upload-${product.id}`}
          disabled={isImageUploading}
        />
        <label
          htmlFor={`image-upload-${product.id}`}
          className={`flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 cursor-pointer ${
            isImageUploading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {isImageUploading ? (
            <Loader className="w-4 h-4 mr-2 animate-spin" />
          ) : (
            <Upload className="w-4 h-4 mr-2" />
          )}
          {isImageUploading ? "Uploading..." : "Add Images"}
        </label>

        {isImageUploading && (
          <div className="mt-2">
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                style={{ width: `${uploadProgress}%` }}
              />
            </div>
            <p className="text-xs text-gray-500 mt-1">{uploadProgress}% Complete</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductImages;