// src/App.js
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebase';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Products from './components/products/Products';
import Categories from './pages/Categories';
import QueryManagement from './pages/Queries';
import ProductDescription from './pages/ProductDescription';
import ProductOrderManager from './pages/Order';
import SofaCategoryUpload from './pages/analyzer';


function AuthGuard({ children }) {
  const [authChecked, setAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function AdminLayout({ children }) {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="pt-16">{children}</div>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route 
          path="/login" 
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } 
        />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <AuthGuard>
              <AdminLayout>
                <Dashboard />
              </AdminLayout>
            </AuthGuard>
          }
        />

        <Route
          path="/products"
          element={
            <AuthGuard>
              <AdminLayout>
                <Products />
              </AdminLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/product-descriptions"
          element={
            <AuthGuard>
              <AdminLayout>
                <ProductDescription />
              </AdminLayout>
            </AuthGuard>
          }
        />

        <Route
          path="/categories"
          element={
            <AuthGuard>
              <AdminLayout>
                <Categories />
              </AdminLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/queries"
          element={
            <AuthGuard>
              <AdminLayout>
                <QueryManagement />
              </AdminLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/order"
          element={
            <AuthGuard>
              <AdminLayout>
                <ProductOrderManager />
              </AdminLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/recovery"
          element={
            <AuthGuard>
              <AdminLayout>
                <SofaCategoryUpload />
              </AdminLayout>
            </AuthGuard>
          }
        />

        {/* Not Found Route */}
        <Route
          path="*"
          element={
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
              <div className="text-center">
                <h1 className="text-6xl font-bold text-gray-900">404</h1>
                <p className="text-xl text-gray-600 mt-4">Page not found</p>
                <Link 
                  to="/"
                  className="mt-6 inline-block px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
                >
                  Go Home
                </Link>
              </div>
            </div>
          }
        />
      </Routes>

      {/* Toast Notifications */}
      <div className="fixed bottom-4 right-4" id="toast-container"></div>
    </Router>
  );
}

// Prevent authenticated users from accessing login page
function PublicRoute({ children }) {
  const [authChecked, setAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
}