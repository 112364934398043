import React, { useState } from 'react';
import { collection, writeBatch, doc } from 'firebase/firestore';
import { db } from '../config/firebase';

const SofaCategoryUpload = () => {
  const [status, setStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const sofaCategoryData = {
    // Parent category
    parent: {
      id: "FQEeC0xYlS2iUhG6of3X",
      name: "Sofa Set",
      numericId: "1",
      imageUrl: "https://res.cloudinary.com/dpwb1gep6/image/upload/v1730714678/vq83hrzryu6ijizwji4v.jpg",
      childCount: 7,
      level: 1,
      createdAt: new Date(),
      updatedAt: new Date()
    },
    // Subcategories
    subcategories: [
      {
        id: "EuLErZtHrL3xkAy6mjzR",
        name: "Wing Chair",
        numericId: "6",
        imageUrl: "",
        parentId: "FQEeC0xYlS2iUhG6of3X",
        level: 2,
        createdAt: new Date(),
        updatedAt: new Date()
      },
      {
        id: "WFWx6WNDtDOcWlAjkeVq",
        name: "Sofa 311",
        numericId: "1",
        imageUrl: "https://res.cloudinary.com/dpwb1gep6/image/upload/v1730719292/nznkk2qevtforjh4xhjm.png",
        parentId: "FQEeC0xYlS2iUhG6of3X",
        level: 2,
        createdAt: new Date(),
        updatedAt: new Date()
      },
      {
        id: "YOhfEZBWH95JLsasTbA6",
        name: "L Shape",
        numericId: "2",
        imageUrl: "https://res.cloudinary.com/dpwb1gep6/image/upload/v1730893773/ew74f1fdcnydaxuzgcrr.jpg",
        parentId: "FQEeC0xYlS2iUhG6of3X",
        level: 2,
        createdAt: new Date(),
        updatedAt: new Date()
      },
      {
        id: "bTrOQoySRdW81uWBewXs",
        name: "Sofa Launcher",
        numericId: "3",
        imageUrl: "https://res.cloudinary.com/dpwb1gep6/image/upload/v1730893805/xellovhcu95ldo4n5ikd.jpg",
        parentId: "FQEeC0xYlS2iUhG6of3X",
        level: 2,
        createdAt: new Date(),
        updatedAt: new Date()
      },
      {
        id: "hLYmdWz3WCsSVu5U8yF1",
        name: "Wood",
        numericId: "5",
        imageUrl: "https://res.cloudinary.com/dpwb1gep6/image/upload/v1730893862/ww17dyxxlxreqhigxc0h.jpg",
        parentId: "FQEeC0xYlS2iUhG6of3X",
        level: 2,
        createdAt: new Date(),
        updatedAt: new Date()
      },
      {
        id: "mSsZ5tQHTQQc1lGM9CSS",
        name: "Sofa Cum Bed",
        numericId: "4",
        imageUrl: "https://res.cloudinary.com/dpwb1gep6/image/upload/v1730893879/czdpg6jko50218iev688.jpg",
        parentId: "FQEeC0xYlS2iUhG6of3X",
        level: 2,
        createdAt: new Date(),
        updatedAt: new Date()
      },
      {
        id: "oBjOwDTtfXxbnNOjlvkC",
        name: "Recliner",
        numericId: "7",
        imageUrl: "https://res.cloudinary.com/dpwb1gep6/image/upload/v1730893916/qh98upzwzwfolpgqwub2.jpg",
        parentId: "FQEeC0xYlS2iUhG6of3X",
        level: 2,
        createdAt: new Date(),
        updatedAt: new Date()
      }
    ]
  };

  const uploadToFirestore = async () => {
    setIsUploading(true);
    setStatus('Starting upload...');
    
    try {
      const batch = writeBatch(db);
      const categoriesRef = collection(db, 'categories');

      // Add parent category
      const parentDocRef = doc(categoriesRef, sofaCategoryData.parent.id);
      batch.set(parentDocRef, sofaCategoryData.parent);

      // Add subcategories
      sofaCategoryData.subcategories.forEach((subcategory) => {
        const subcategoryDocRef = doc(categoriesRef, subcategory.id);
        batch.set(subcategoryDocRef, subcategory);
      });

      await batch.commit();
      setStatus('Successfully uploaded all categories!');
    } catch (error) {
      console.error('Error uploading categories:', error);
      setStatus(`Error: ${error.message}`);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Sofa Category Upload</h1>
      
      <div style={styles.statusContainer}>
        <p style={styles.statusText}>Status: {status}</p>
      </div>

      <button 
        onClick={uploadToFirestore}
        disabled={isUploading}
        style={{
          ...styles.button,
          ...(isUploading ? styles.buttonDisabled : {})
        }}
      >
        {isUploading ? 'Uploading...' : 'Upload to Firestore'}
      </button>

      <div style={styles.dataPreview}>
        <h2 style={styles.subtitle}>Data to be uploaded:</h2>
        <pre style={styles.pre}>
          {JSON.stringify(sofaCategoryData, null, 2)}
        </pre>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  subtitle: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  statusContainer: {
    marginBottom: '20px',
  },
  statusText: {
    fontSize: '16px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  buttonDisabled: {
    backgroundColor: '#cccccc',
    cursor: 'not-allowed',
  },
  dataPreview: {
    marginTop: '20px',
  },
  pre: {
    backgroundColor: '#f5f5f5',
    padding: '15px',
    borderRadius: '4px',
    overflow: 'auto',
    fontSize: '14px',
  }
};

export default SofaCategoryUpload;