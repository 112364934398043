// src/config/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: "AIzaSyDcwiuIwnX7UtoLR3O9YiysBducTgN4ieo",
//   authDomain: "malnad-furnitures-9b21a.firebaseapp.com",
//   projectId: "malnad-furnitures-9b21a",
//   storageBucket: "malnad-furnitures-9b21a.firebasestorage.app",
//   messagingSenderId: "231247380439",
//   appId: "1:231247380439:web:77f425734ae9ef57159592"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDTzPa62tCXixCb-78YBcqgxilnOt_haRQ",
//   authDomain: "testproject-5b991.firebaseapp.com",
//   projectId: "testproject-5b991",
//   storageBucket: "testproject-5b991.firebasestorage.app",
//   messagingSenderId: "100221511887",
//   appId: "1:100221511887:web:420a131becfe0cfa6cefba"
// };

const firebaseConfig = {
  apiKey: "AIzaSyB6-VECgKfgTjW4ls07W20Rgljg4g5zjn8",
  authDomain: "malnad-furnitures-3d4e3.firebaseapp.com",
  projectId: "malnad-furnitures-3d4e3",
  storageBucket: "malnad-furnitures-3d4e3.firebasestorage.app",
  messagingSenderId: "723643264379",
  appId: "1:723643264379:web:aa45853d11a0b1a240a1de"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);