import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { db } from "../config/firebase";
import {
  GripVertical,
  ChevronDown,
  ChevronRight,
  Loader,
  AlertCircle,
  RefreshCw,
  ArrowDown,
  ArrowUp,
} from "lucide-react";

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds
const AUTOSCROLL_THRESHOLD = 100; // pixels from edge to trigger auto-scroll
const SCROLL_SPEED = 10; // pixels per scroll tick

const ProductOrderManager = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [draggedProduct, setDraggedProduct] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [statistics, setStatistics] = useState({
    total: 0,
    withId: 0,
    withoutId: 0,
    categories: {},
  });
  const productRefs = useRef({});
  const [productCache, setProductCache] = useState(new Map());
  const scrollContainerRef = useRef(null);
  const autoScrollIntervalRef = useRef(null);

  // Check if category has subcategories
  const hasSubcategories = useCallback(
    (categoryId) => {
      return categories.some((cat) => cat.parentId === categoryId);
    },
    [categories]
  );

  // Auto-scroll function
  const handleAutoScroll = (e) => {
    if (!scrollContainerRef.current) return;

    const container = scrollContainerRef.current;
    const containerRect = container.getBoundingClientRect();
    const mouseY = e.clientY;

    // Clear existing interval
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
      autoScrollIntervalRef.current = null;
    }

    // Calculate distances from top and bottom edges
    const distanceFromTop = mouseY - containerRect.top;
    const distanceFromBottom = containerRect.bottom - mouseY;

    // Auto-scroll logic
    if (distanceFromTop < AUTOSCROLL_THRESHOLD) {
      // Scroll up
      autoScrollIntervalRef.current = setInterval(() => {
        container.scrollTop -= SCROLL_SPEED;
      }, 16);
    } else if (distanceFromBottom < AUTOSCROLL_THRESHOLD) {
      // Scroll down
      autoScrollIntervalRef.current = setInterval(() => {
        container.scrollTop += SCROLL_SPEED;
      }, 16);
    }
  };

  // Scroll to product function
  const scrollToProduct = (productId) => {
    const productElement = productRefs.current[productId];
    if (productElement) {
      productElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const snapshot = await getDocs(collection(db, "categories"));
        const categoriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          name: doc.data().name || "",
          parentId: doc.data().parentId || null,
          numericId: doc.data().numericId || 0,
        }));

        const sortedCategories = categoriesData.sort(
          (a, b) => a.numericId - b.numericId
        );
        setCategories(sortedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Check if cache is valid
  const isCacheValid = useCallback((cacheEntry) => {
    if (!cacheEntry) return false;
    return Date.now() - cacheEntry.timestamp < CACHE_DURATION;
  }, []);

  // Fetch products for a single category
  const fetchCategoryProducts = async (categoryId) => {
    const productsQuery = query(
      collection(db, "products"),
      where("categoryId", "==", categoryId)
    );
    const snapshot = await getDocs(productsQuery);

    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  // Reorder products function
  const reorderProducts = (products, startId, newPosition) => {
    const newIndex = newPosition - 1;
    const productToMove = products.find((p) => p.sequenceId === startId);
    if (!productToMove) return products;

    const filteredProducts = products.filter((p) => p.sequenceId !== startId);
    const reorderedProducts = [
      ...filteredProducts.slice(0, newIndex),
      productToMove,
      ...filteredProducts.slice(newIndex),
    ];

    return reorderedProducts.map((product, index) => ({
      ...product,
      sequenceId: index + 1,
    }));
  };

  // Fetch products and calculate statistics
  const fetchProducts = async (categoryId) => {
    if (!categoryId) return;

    setLoading(true);
    try {
      let categoryProducts = [];
      const stats = { total: 0, withId: 0, withoutId: 0, categories: {} };

      const cachedData = productCache.get(categoryId);
      if (isCacheValid(cachedData)) {
        categoryProducts = cachedData.products;
      } else {
        categoryProducts = await fetchCategoryProducts(categoryId);
        setProductCache((prev) =>
          new Map(prev).set(categoryId, {
            products: categoryProducts,
            timestamp: Date.now(),
          })
        );
      }

      const categoryStats = {
        total: categoryProducts.length,
        withId: categoryProducts.filter((p) => p.sequenceId !== undefined)
          .length,
        withoutId: categoryProducts.filter((p) => p.sequenceId === undefined)
          .length,
      };

      stats.categories[categoryId] = categoryStats;
      stats.total = categoryStats.total;
      stats.withId = categoryStats.withId;
      stats.withoutId = categoryStats.withoutId;

      const sortedProducts = categoryProducts.sort((a, b) => {
        if (a.sequenceId !== undefined && b.sequenceId !== undefined) {
          return a.sequenceId - b.sequenceId;
        }
        if (a.sequenceId === undefined && b.sequenceId === undefined) {
          return (a.name || "").localeCompare(b.name || "");
        }
        return a.sequenceId !== undefined ? -1 : 1;
      });

      setProducts(sortedProducts);
      setStatistics(stats);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  // Process missing IDs for a category
  const handleProcessIds = async (categoryId) => {
    setProcessing(true);
    try {
      const batch = writeBatch(db);
      const productsToUpdate = products.filter(
        (p) => p.sequenceId === undefined
      );

      const maxId = Math.max(0, ...products.map((p) => p.sequenceId || 0));

      productsToUpdate.forEach((product, index) => {
        const productRef = doc(db, "products", product.id);
        batch.update(productRef, { sequenceId: maxId + index + 1 });
      });

      await batch.commit();

      setProductCache((prev) => {
        const newCache = new Map(prev);
        newCache.delete(categoryId);
        return newCache;
      });

      await fetchProducts(selectedCategory);
    } catch (error) {
      console.error("Error processing IDs:", error);
    } finally {
      setProcessing(false);
    }
  };

  // Enhanced update sequence function
  const updateProductSequence = async (productId, newSequenceId) => {
    try {
      const currentProduct = products.find((p) => p.id === productId);
      if (!currentProduct) return;

      const oldSequenceId = currentProduct.sequenceId;
      if (oldSequenceId === newSequenceId) return;

      const reorderedProducts = reorderProducts(
        products,
        oldSequenceId,
        newSequenceId
      );

      const batch = writeBatch(db);
      reorderedProducts.forEach((product) => {
        const productRef = doc(db, "products", product.id);
        batch.update(productRef, { sequenceId: product.sequenceId });
      });

      await batch.commit();

      setProductCache((prev) => {
        const newCache = new Map(prev);
        newCache.delete(selectedCategory);
        return newCache;
      });

      setProducts(reorderedProducts);

      setTimeout(() => {
        scrollToProduct(productId);
      }, 100);
    } catch (error) {
      console.error("Error updating sequence:", error);
    }
  };

  // Drag and drop handlers
  const handleDragStart = (product) => {
    setDraggedProduct(product);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    handleAutoScroll(e);
  };

  const handleDragEnd = () => {
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
      autoScrollIntervalRef.current = null;
    }
    setDraggedProduct(null);
  };

  const handleDrop = async (targetProduct) => {
    if (!draggedProduct || draggedProduct.id === targetProduct.id) return;
    await updateProductSequence(draggedProduct.id, targetProduct.sequenceId);
    setDraggedProduct(null);
  };

  // Category expansion toggle
  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) => {
      const next = new Set(prev);
      if (next.has(categoryId)) {
        next.delete(categoryId);
      } else {
        next.add(categoryId);
      }
      return next;
    });
  };

  // Handle category click
  const handleCategoryClick = (category) => {
    const hasSubcats = hasSubcategories(category.id);

    if (hasSubcats) {
      toggleCategory(category.id);
      if (selectedCategory === category.id) {
        setSelectedCategory(null);
        setProducts([]);
      }
    } else {
      setSelectedCategory(category.id);
      fetchProducts(category.id);
    }
  };

  // Get category path
  const getCategoryPath = (categoryId) => {
    const path = [];
    let currentCat = categories.find((cat) => cat.id === categoryId);

    while (currentCat) {
      path.unshift(currentCat.name);
      currentCat = categories.find((cat) => cat.id === currentCat.parentId);
    }

    return path.join(" > ");
  };

  // Render category tree item
  const renderCategory = (category, level = 0) => {
    const isExpanded = expandedCategories.has(category.id);
    const subcategories = categories.filter(
      (cat) => cat.parentId === category.id
    );
    const hasSubcats = subcategories.length > 0;
    const isSelected = selectedCategory === category.id;
    const stats = statistics.categories[category.id];

    return (
      <div key={category.id} className="select-none">
        <div
          className={`
            flex items-center px-4 py-2 cursor-pointer hover:bg-gray-50
            ${isSelected && !hasSubcats ? "bg-blue-50" : ""}
            ${hasSubcats ? "font-medium" : "hover:bg-blue-50"}
          `}
          style={{ paddingLeft: `${level * 20 + 16}px` }}
          onClick={() => handleCategoryClick(category)}
        >
          {hasSubcats &&
            (isExpanded ? (
              <ChevronDown className="w-5 h-5 mr-2" />
            ) : (
              <ChevronRight className="w-5 h-5 mr-2" />
            ))}
          <span
            className={`flex-1 ${
              isSelected && !hasSubcats ? "text-blue-600" : ""
            }`}
          >
            {category.name}
          </span>
          {stats && stats.withoutId > 0 && !hasSubcats && (
            <span className="text-xs px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full">
              {stats.withoutId}
            </span>
          )}
        </div>

        {isExpanded &&
          subcategories.map((subcat) => renderCategory(subcat, level + 1))}
      </div>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-4 gap-6">
        {/* Category Tree */}
        <div className="col-span-1 bg-white rounded-lg shadow-lg p-4">
          <h2 className="text-lg font-semibold mb-4">Categories</h2>
          <div className="border rounded-lg overflow-hidden">
            {categories
              .filter((cat) => !cat.parentId)
              .map((category) => renderCategory(category))}
          </div>
        </div>

        {/* Products List */}
        <div className="col-span-3 bg-white rounded-lg shadow-lg p-4">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-semibold">Products</h2>
            {selectedCategory && !hasSubcategories(selectedCategory) && (
              <div className="flex gap-4 items-center">
                <div className="flex gap-4 text-sm">
                  <div className="px-3 py-1 bg-green-100 text-green-800 rounded-full">
                    With ID: {statistics.withId}
                  </div>
                  <div className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full">
                    Without ID: {statistics.withoutId}
                  </div>
                  <div className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full">
                    Total: {statistics.total}
                  </div>
                </div>
                <button
                  onClick={() => handleProcessIds(selectedCategory)}
                  disabled={processing || statistics.withoutId === 0}
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {processing ? (
                    <Loader className="w-4 h-4 animate-spin mr-2" />
                  ) : (
                    <RefreshCw className="w-4 h-4 mr-2" />
                  )}
                  Process Missing IDs
                </button>
              </div>
            )}
          </div>

          {loading ? (
            <div className="flex items-center justify-center py-12">
              <Loader className="w-8 h-8 animate-spin text-blue-500" />
            </div>
          ) : !selectedCategory ? (
            <div className="flex flex-col items-center justify-center py-12">
              <AlertCircle className="w-12 h-12 text-gray-400 mb-2" />
              <p className="text-gray-500">
                Please select a category to view products
              </p>
            </div>
          ) : hasSubcategories(selectedCategory) ? (
            <div className="flex flex-col items-center justify-center py-12">
              <AlertCircle className="w-12 h-12 text-gray-400 mb-2" />
              <p className="text-gray-500">
                Please select a subcategory to view products
              </p>
            </div>
          ) : products.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-12">
              <AlertCircle className="w-12 h-12 text-gray-400 mb-2" />
              <p className="text-gray-500">
                No products found in this category
              </p>
            </div>
          ) : (
            <div
              ref={scrollContainerRef}
              className="space-y-4 max-h-[calc(100vh-240px)] overflow-y-auto"
            >
              {products.map((product) => (
                <div
                  key={product.id}
                  ref={(el) => (productRefs.current[product.id] = el)}
                  draggable
                  onDragStart={() => handleDragStart(product)}
                  onDragOver={handleDragOver}
                  onDrop={() => handleDrop(product)}
                  onDragEnd={handleDragEnd}
                  className={`
                    flex items-center gap-4 p-3 border rounded-lg cursor-move
                    ${draggedProduct?.id === product.id ? "opacity-50" : ""}
                    ${
                      product.sequenceId === undefined
                        ? "border-yellow-300 bg-yellow-50"
                        : ""
                    }
                    hover:bg-gray-50 relative group
                  `}
                >
                  <GripVertical className="w-5 h-5 text-gray-400" />
                  <span className="w-12 text-center font-medium text-gray-500">
                    #{product.sequenceId || "?"}
                  </span>
                  <div className="flex-1">
                    <h3 className="font-medium">{product.name}</h3>
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-gray-500">
                        {getCategoryPath(product.categoryId)}
                      </span>
                      {product.sequenceId === undefined && (
                        <span className="text-xs px-2 py-0.5 bg-yellow-100 text-yellow-700 rounded-full">
                          No Sequence ID
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="relative">
                      <div className="w-20 px-2 py-1 border rounded text-sm bg-gray-50">
                        {product.sequenceId || "-"}
                      </div>
                      <div className="absolute right-0 top-0 bottom-0 flex flex-col opacity-0 group-hover:opacity-100 transition-opacity">
                        <button
                          onClick={() => {
                            const newId = Math.max(
                              1,
                              (product.sequenceId || 1) - 1
                            );
                            updateProductSequence(product.id, newId);
                          }}
                          className="px-1 hover:bg-gray-100"
                        >
                          <ArrowUp className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => {
                            const newId = Math.min(
                              products.length,
                              (product.sequenceId || 1) + 1
                            );
                            updateProductSequence(product.id, newId);
                          }}
                          className="px-1 hover:bg-gray-100"
                        >
                          <ArrowDown className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                    {product.sequenceId === undefined && (
                      <AlertCircle className="w-5 h-5 text-yellow-500" />
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductOrderManager;
