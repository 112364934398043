import React, { useState } from "react";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import ProductImages from "./ProductImages";
import EditableField from "./EditableField";

const ProductRow = ({ 
  product, 
  categories, 
  updateProductLocally, 
  deleteProductLocally 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempPrice, setTempPrice] = useState({
    originalPrice: product.originalPrice,
    discountPercentage: product.discountPercentage,
    discountedPrice: product.discountedPrice,
  });

  // Helper function to round to 2 decimal places
  const roundToTwo = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  // Helper function to ensure percentage is between 0 and 100
  const validatePercentage = (value) => {
    return Math.min(Math.max(value, 0), 100);
  };

  // Calculate discounted price from percentage
  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    if (!originalPrice || discountPercentage === undefined) return null;
    const discount = (parseFloat(discountPercentage) / 100) * parseFloat(originalPrice);
    return roundToTwo(parseFloat(originalPrice) - discount);
  };

  // Calculate discount percentage from discounted price
  const calculateDiscountPercentage = (originalPrice, discountedPrice) => {
    if (!originalPrice || !discountedPrice) return 0;
    const percentage = ((originalPrice - discountedPrice) / originalPrice) * 100;
    return roundToTwo(validatePercentage(percentage));
  };

  const getCategoryPath = (categoryId) => {
    const result = [];
    let currentCat = categories.find((cat) => cat.id === categoryId);

    while (currentCat) {
      result.unshift(currentCat.name);
      currentCat = categories.find((cat) => cat.id === currentCat.parentId);
    }

    return result.join(" > ");
  };

  const handleDelete = async (productId) => {
    if (!window.confirm("Are you sure you want to delete this product? This action cannot be undone.")) {
      return;
    }
    
    try {
      await deleteDoc(doc(db, "products", productId));
      deleteProductLocally(productId);
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("Failed to delete product. Please try again.");
    }
  };

  const handlePriceChange = (value, field) => {
    const parsedValue = parseFloat(value) || 0;
    let updates = { ...tempPrice };

    if (field === "originalPrice") {
      updates.originalPrice = roundToTwo(parsedValue);
      if (updates.discountPercentage !== undefined) {
        updates.discountedPrice = calculateDiscountedPrice(updates.originalPrice, updates.discountPercentage);
      }
    } else if (field === "discountPercentage") {
      updates.discountPercentage = validatePercentage(parsedValue);
      updates.discountedPrice = calculateDiscountedPrice(updates.originalPrice, updates.discountPercentage);
    } else if (field === "discountedPrice") {
      updates.discountedPrice = roundToTwo(Math.min(parsedValue, updates.originalPrice));
      updates.discountPercentage = calculateDiscountPercentage(updates.originalPrice, updates.discountedPrice);
    }

    setTempPrice(updates);
    setIsEditing(true);
  };

  const handleSavePrice = async () => {
    try {
      const productRef = doc(db, "products", product.id);
      const updates = {
        originalPrice: tempPrice.originalPrice,
        discountPercentage: tempPrice.discountPercentage,
        discountedPrice: tempPrice.discountedPrice
      };

      await updateDoc(productRef, updates);
      updateProductLocally(product.id, updates);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating prices:", error);
      alert("Failed to update prices. Please try again.");
    }
  };

  return (
    <tr className="hover:bg-gray-50">
      <td className="px-6 py-4">
        <ProductImages
          product={product}
          updateProductLocally={updateProductLocally}
        />
      </td>
      
      <td className="px-6 py-4">
        <EditableField
          product={product}
          field="name"
          value={product.name}
          placeholder="Enter product name"
          updateProductLocally={updateProductLocally}
        />
      </td>
      
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {getCategoryPath(product.categoryId)}
        </div>
      </td>
      
      <td className="px-6 py-4">
        <EditableField
          product={product}
          field="originalPrice"
          value={tempPrice.originalPrice}
          placeholder="Enter price"
          type="number"
          step="0.01"
          min="0"
          onUpdate={(value) => handlePriceChange(value, "originalPrice")}
          prefix="₹"
          immediateUpdate={false}
          updateProductLocally={updateProductLocally}
        />
      </td>
      
      <td className="px-6 py-4">
        <EditableField
          product={product}
          field="discountPercentage"
          value={tempPrice.discountPercentage}
          placeholder="Enter discount"
          type="number"
          step="0.1"
          min="0"
          max="100"
          onUpdate={(value) => handlePriceChange(value, "discountPercentage")}
          suffix="%"
          immediateUpdate={false}
          updateProductLocally={updateProductLocally}
        />
      </td>
      
      <td className="px-6 py-4">
        <EditableField
          product={product}
          field="discountedPrice"
          value={tempPrice.discountedPrice}
          placeholder="Enter final price"
          type="number"
          step="0.01"
          min="0"
          max={tempPrice.originalPrice}
          onUpdate={(value) => handlePriceChange(value, "discountedPrice")}
          prefix="₹"
          immediateUpdate={false}
          updateProductLocally={updateProductLocally}
        />
      </td>
      
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <div className="flex flex-col space-y-2">
          {isEditing && (
            <button
              onClick={handleSavePrice}
              className="text-green-600 hover:text-green-900 bg-green-50 hover:bg-green-100 px-3 py-1.5 rounded transition-colors"
            >
              Save
            </button>
          )}
          
          <button
            onClick={() => handleDelete(product.id)}
            className="text-red-600 hover:text-red-900 bg-red-50 hover:bg-red-100 px-3 py-1.5 rounded transition-colors"
          >
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ProductRow;