import React, { useState, useEffect } from 'react';
import { db } from "../config/firebase";
import { 
  collection, 
  query, 
  orderBy, 
  getDocs, 
  deleteDoc, 
  doc, 
  updateDoc 
} from "firebase/firestore";
import { 
  Trash2, 
  RefreshCcw, 
  Mail, 
  CheckCircle2, 
  Circle,
  Filter,
  AlertCircle,
  CheckCircle,
  User,
  Phone,
  MessageSquare,
  Calendar
} from 'lucide-react';

const QueryManagement = () => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });

  const fetchQueries = async () => {
    setLoading(true);
    try {
      const queryRef = query(collection(db, "queries"), orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(queryRef);
      const queriesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        readStatus: doc.data().readStatus || false,
        timestamp: doc.data().timestamp?.toDate().toLocaleString() || 'N/A'
      }));
      setQueries(queriesData);
      setError(null);
    } catch (err) {
      console.error("Error fetching queries:", err);
      setError("Failed to load queries. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQueries();
  }, []);

  const handleDelete = async (queryId) => {
    if (window.confirm('Are you sure you want to delete this query?')) {
      try {
        await deleteDoc(doc(db, "queries", queryId));
        showNotification('Query deleted successfully!', 'success');
        fetchQueries();
      } catch (err) {
        console.error("Error deleting query:", err);
        showNotification('Failed to delete query. Please try again.', 'error');
      }
    }
  };

  const handleReadStatus = async (queryId, currentStatus) => {
    try {
      await updateDoc(doc(db, "queries", queryId), {
        readStatus: !currentStatus
      });
      showNotification(`Query marked as ${!currentStatus ? 'read' : 'unread'}`, 'success');
      fetchQueries();
    } catch (err) {
      console.error("Error updating read status:", err);
      showNotification('Failed to update status. Please try again.', 'error');
    }
  };

  const showNotification = (message, type) => {
    setNotification({ show: true, message, type });
    setTimeout(() => {
      setNotification({ show: false, message: '', type: '' });
    }, 3000);
  };

  const filteredQueries = queries.filter(query => {
    if (statusFilter === "read") return query.readStatus;
    if (statusFilter === "unread") return !query.readStatus;
    return true;
  });

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Query Management</h1>
          <div className="flex gap-4">
            <select
              className="px-4 py-2 border rounded-lg"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">All Queries</option>
              <option value="read">Read</option>
              <option value="unread">Unread</option>
            </select>
            <button
              onClick={fetchQueries}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <RefreshCcw className="w-4 h-4 mr-2" />
              Refresh
            </button>
          </div>
        </div>

        {/* Notification */}
        {notification.show && (
          <div className={`flex items-center p-4 mb-4 rounded-lg ${
            notification.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          }`}>
            {notification.type === 'success' ? 
              <CheckCircle className="w-5 h-5 mr-2" /> : 
              <AlertCircle className="w-5 h-5 mr-2" />
            }
            {notification.message}
          </div>
        )}

        {/* Loading State */}
        {loading ? (
          <div className="text-center py-8">Loading queries...</div>
        ) : error ? (
          <div className="bg-red-100 text-red-800 p-4 rounded-lg flex items-center">
            <AlertCircle className="w-5 h-5 mr-2" />
            {error}
          </div>
        ) : filteredQueries.length === 0 ? (
          <div className="text-center py-8 text-gray-500">No queries found</div>
        ) : (
          <div className="space-y-4">
            {filteredQueries.map((query) => (
              <div
                key={query.id}
                className="border rounded-lg p-4 hover:shadow-md transition-shadow"
              >
                <div className="flex justify-between items-start">
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <User className="w-4 h-4 text-gray-500" />
                      <span className="font-medium">{query.name}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Mail className="w-4 h-4 text-gray-500" />
                      <span>{query.email}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Phone className="w-4 h-4 text-gray-500" />
                      <span>{query.phone}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <MessageSquare className="w-4 h-4 text-gray-500" />
                      <span>{query.message}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Calendar className="w-4 h-4 text-gray-500" />
                      <span className="text-sm text-gray-500">{query.timestamp}</span>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleReadStatus(query.id, query.readStatus)}
                      className={`p-2 rounded-lg ${
                        query.readStatus ? 'text-green-600' : 'text-gray-400'
                      } hover:bg-gray-100`}
                      title={query.readStatus ? "Mark as unread" : "Mark as read"}
                    >
                      {query.readStatus ? 
                        <CheckCircle2 className="w-5 h-5" /> : 
                        <Circle className="w-5 h-5" />
                      }
                    </button>
                    <button
                      onClick={() => handleDelete(query.id)}
                      className="p-2 text-red-600 hover:bg-gray-100 rounded-lg"
                      title="Delete query"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default QueryManagement;