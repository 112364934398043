import React, { useState, useCallback, useReducer, useMemo } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import {
  Check,
  ChevronRight,
  Folder,
  FolderOpen,
  Loader,
  Plus,
  Trash2,
  Upload,
} from "lucide-react";

// Initial state for a single product
const initialProductState = {
  name: "",
  categoryId: "",
  images: [],
  originalPrice: "",
  discountPercentage: "",
  discountedPrice: "",
  isUploading: false,
};

// Action types for reducer
const ACTIONS = {
  ADD_PRODUCT: "ADD_PRODUCT",
  REMOVE_PRODUCT: "REMOVE_PRODUCT",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  UPDATE_PRODUCT_IMAGES: "UPDATE_PRODUCT_IMAGES",
  SET_UPLOADING: "SET_UPLOADING",
  RESET_PRODUCTS: "RESET_PRODUCTS",
  SET_CATEGORY_ALL: "SET_CATEGORY_ALL",
};

// Reducer for managing multiple products
function productsReducer(state, action) {
  switch (action.type) {
    case ACTIONS.ADD_PRODUCT:
      return [
        ...state,
        {
          ...initialProductState,
          id: crypto.randomUUID(),
          categoryId: state[0]?.categoryId || "",
        },
      ];

    case ACTIONS.REMOVE_PRODUCT:
      return state.filter((product) => product.id !== action.payload.id);

    case ACTIONS.UPDATE_PRODUCT:
      return state.map((product) =>
        product.id === action.payload.id
          ? { ...product, [action.payload.field]: action.payload.value }
          : product
      );

    case ACTIONS.UPDATE_PRODUCT_IMAGES:
      return state.map((product) =>
        product.id === action.payload.id
          ? { ...product, images: action.payload.images }
          : product
      );

    case ACTIONS.SET_UPLOADING:
      return state.map((product) =>
        product.id === action.payload.id
          ? { ...product, isUploading: action.payload.isUploading }
          : product
      );

    case ACTIONS.SET_CATEGORY_ALL:
      return state.map((product) => ({
        ...product,
        categoryId: action.payload.categoryId,
      }));

    case ACTIONS.RESET_PRODUCTS:
      return [{ ...initialProductState, id: crypto.randomUUID() }];

    default:
      return state;
  }
}

// Price Fields Component
const PriceFields = ({ product, dispatch }) => {
  const roundToTwo = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const validatePercentage = (value) => {
    return Math.min(Math.max(value || 0, 0), 100);
  };

  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    if (!originalPrice || discountPercentage === undefined) return "";
    const discount =
      (parseFloat(discountPercentage) / 100) * parseFloat(originalPrice);
    return roundToTwo(parseFloat(originalPrice) - discount);
  };

  const calculateDiscountPercentage = (originalPrice, discountedPrice) => {
    if (!originalPrice || !discountedPrice) return "";
    const percentage =
      ((originalPrice - discountedPrice) / originalPrice) * 100;
    return roundToTwo(validatePercentage(percentage));
  };

  const handlePriceChange = (value, field) => {
    const parsedValue = parseFloat(value) || 0;
    let updates = {
      originalPrice: product.originalPrice,
      discountPercentage: product.discountPercentage,
      discountedPrice: product.discountedPrice,
    };

    if (field === "originalPrice") {
      updates.originalPrice = roundToTwo(parsedValue);
      if (updates.discountPercentage) {
        updates.discountedPrice = calculateDiscountedPrice(
          updates.originalPrice,
          updates.discountPercentage
        );
      }
    } else if (field === "discountPercentage") {
      updates.discountPercentage = validatePercentage(parsedValue);
      updates.discountedPrice = calculateDiscountedPrice(
        updates.originalPrice,
        updates.discountPercentage
      );
    } else if (field === "discountedPrice") {
      updates.discountedPrice = roundToTwo(
        Math.min(parsedValue, updates.originalPrice || Infinity)
      );
      updates.discountPercentage = calculateDiscountPercentage(
        updates.originalPrice,
        updates.discountedPrice
      );
    }

    Object.entries(updates).forEach(([field, value]) => {
      dispatch({
        type: ACTIONS.UPDATE_PRODUCT,
        payload: {
          id: product.id,
          field,
          value: value.toString(),
        },
      });
    });
  };

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Original Price
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">₹</span>
          </div>
          <input
            type="number"
            value={product.originalPrice}
            onChange={(e) => handlePriceChange(e.target.value, "originalPrice")}
            step="0.01"
            min="0"
            className="block w-full pl-7 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
            placeholder="0.00"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Discount Percentage
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="number"
            value={product.discountPercentage}
            onChange={(e) =>
              handlePriceChange(e.target.value, "discountPercentage")
            }
            step="0.1"
            min="0"
            max="100"
            className="block w-full pr-12 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
            placeholder="0.00"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">%</span>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Final Price
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">₹</span>
          </div>
          <input
            type="number"
            value={product.discountedPrice}
            onChange={(e) =>
              handlePriceChange(e.target.value, "discountedPrice")
            }
            step="0.01"
            min="0"
            max={product.originalPrice || Infinity}
            className="block w-full pl-7 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
            placeholder="0.00"
          />
        </div>
      </div>

      {product.originalPrice && product.discountedPrice && (
        <div className="text-sm text-gray-600">
          You save: ₹
          {(product.originalPrice - product.discountedPrice).toFixed(2)}(
          {product.discountPercentage}%)
        </div>
      )}
    </div>
  );
};

const CreateProduct = ({ categories }) => {
  const [products, dispatch] = useReducer(productsReducer, [
    { ...initialProductState, id: crypto.randomUUID() },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState(new Set());

  // Process categories into a hierarchical structure
  const categoryHierarchy = useMemo(() => {
    const categoryMap = new Map();
    const rootCategories = [];

    categories.forEach((category) => {
      categoryMap.set(category.id, {
        ...category,
        children: [],
        level: 0,
        hasChildren: categories.some((c) => c.parentId === category.id),
      });
    });

    categories.forEach((category) => {
      const categoryObject = categoryMap.get(category.id);
      if (category.parentId) {
        const parent = categoryMap.get(category.parentId);
        if (parent) {
          categoryObject.level = parent.level + 1;
          parent.children.push(categoryObject);
        }
      } else {
        rootCategories.push(categoryObject);
      }
    });

    return rootCategories;
  }, [categories]);

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) => {
      const next = new Set(prev);
      if (next.has(categoryId)) {
        next.delete(categoryId);
      } else {
        next.add(categoryId);
      }
      return next;
    });
  };

  const CategoryItem = useCallback(
    ({ category, onSelect }) => {
      const isExpanded = expandedCategories.has(category.id);
      const hasChildren = category.children.length > 0;
      const isSelectable = !hasChildren;

      return (
        <div className="select-none">
          <div
            className={`
              flex items-center px-3 py-2 rounded-lg transition-colors
              ${
                isSelectable
                  ? "hover:bg-gray-100 cursor-pointer"
                  : "cursor-default"
              }
              ${products[0]?.categoryId === category.id ? "bg-primary/10" : ""}
            `}
            style={{ marginLeft: `${category.level * 1.5}rem` }}
            onClick={() => {
              if (hasChildren) {
                toggleCategory(category.id);
              } else {
                onSelect(category.id);
              }
            }}
          >
            <div className="flex items-center flex-1">
              {hasChildren ? (
                <ChevronRight
                  className={`w-4 h-4 mr-2 transition-transform ${
                    isExpanded ? "rotate-90" : ""
                  }`}
                />
              ) : (
                <div className="w-6" />
              )}
              {hasChildren ? (
                isExpanded ? (
                  <FolderOpen className="w-5 h-5 mr-2 text-yellow-500" />
                ) : (
                  <Folder className="w-5 h-5 mr-2 text-yellow-500" />
                )
              ) : (
                <div className="w-2 h-2 rounded-full bg-gray-400 mx-2" />
              )}
              <span
                className={`${hasChildren ? "font-medium" : ""} ${
                  !isSelectable ? "text-gray-500" : ""
                }`}
              >
                {category.name}
              </span>
            </div>
            {isSelectable && products[0]?.categoryId === category.id && (
              <Check className="w-4 h-4 text-primary" />
            )}
          </div>
          {isExpanded &&
            category.children.map((child) => (
              <CategoryItem
                key={child.id}
                category={child}
                onSelect={onSelect}
              />
            ))}
        </div>
      );
    },
    [expandedCategories, products]
  );

  const handleImageUpload = useCallback(
    async (event, productId) => {
      const files = Array.from(event.target.files);
      if (files.length === 0) return;

      const product = products.find((p) => p.id === productId);
      if (product.isUploading) return;

      if (product.images.length + files.length > 5) {
        alert("Maximum 5 images allowed per product");
        return;
      }

      dispatch({
        type: ACTIONS.SET_UPLOADING,
        payload: { id: productId, isUploading: true },
      });

      try {
        const uploadedImages = [];

        for (const file of files) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("upload_preset", "default");
          formData.append("cloud_name", "dpwb1gep6");

          const response = await fetch(
            `https://api.cloudinary.com/v1_1/dpwb1gep6/image/upload`,
            {
              method: "POST",
              body: formData,
            }
          );

          const data = await response.json();

          if (data.secure_url) {
            uploadedImages.push({
              id: data.public_id,
              publicId: data.public_id,
              url: data.secure_url,
              isMain:
                product.images.length === 0 && uploadedImages.length === 0,
              uploadedAt: new Date().toISOString(),
            });
          }
        }

        const newImages = [...product.images, ...uploadedImages];
        dispatch({
          type: ACTIONS.UPDATE_PRODUCT_IMAGES,
          payload: { id: productId, images: newImages },
        });
      } catch (error) {
        console.error("Error during image upload:", error);
        alert("Failed to upload images. Please try again.");
      } finally {
        dispatch({
          type: ACTIONS.SET_UPLOADING,
          payload: { id: productId, isUploading: false },
        });
      }
    },
    [products]
  );

  const handleDeleteImage = useCallback(
    (productId, imageId) => {
      const product = products.find((p) => p.id === productId);
      if (!product || product.isUploading) return;

      const newImages = product.images.filter((img) => img.id !== imageId);

      if (
        newImages.length > 0 &&
        product.images.find((img) => img.id === imageId)?.isMain
      ) {
        newImages[0].isMain = true;
      }

      dispatch({
        type: ACTIONS.UPDATE_PRODUCT_IMAGES,
        payload: { id: productId, images: newImages },
      });
    },
    [products]
  );

  const handleSetMainImage = useCallback(
    (productId, imageId) => {
      const product = products.find((p) => p.id === productId);
      if (!product || product.isUploading) return;

      const newImages = product.images.map((img) => ({
        ...img,
        isMain: img.id === imageId,
      }));

      dispatch({
        type: ACTIONS.UPDATE_PRODUCT_IMAGES,
        payload: { id: productId, images: newImages },
      });
    },
    [products]
  );

  const validateProducts = useCallback(() => {
    return products.every(
      (product) =>
        product.name &&
        product.categoryId &&
        product.images.length > 0 &&
        !product.isUploading &&
        (product.originalPrice === "" ||
          !isNaN(parseFloat(product.originalPrice)))
    );
  }, [products]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateProducts()) {
      alert("Please check all fields are valid");
      return;
    }

    setIsSubmitting(true);

    try {
      const batch = products.map((product) => {
        const originalPrice = product.originalPrice
          ? parseFloat(product.originalPrice)
          : null;
        const discountPercentage = product.discountPercentage
          ? parseFloat(product.discountPercentage)
          : null;
        const discountedPrice = product.discountedPrice
          ? parseFloat(product.discountedPrice)
          : null;

        return addDoc(collection(db, "products"), {
          name: product.name,
          categoryId: product.categoryId,
          images: product.images,
          originalPrice,
          discountPercentage,
          discountedPrice,
          createdAt: new Date(),
        });
      });

      await Promise.all(batch);
      dispatch({ type: ACTIONS.RESET_PRODUCTS });
      alert("All products created successfully!");

      // Optionally reload the page or redirect
      window.location.reload();
    } catch (error) {
      console.error("Error saving products:", error);
      alert("Failed to save products. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mt-6">
      <h2 className="text-xl font-semibold mb-6">Create Multiple Products</h2>

      <div className="mb-8 p-4 border rounded-lg bg-gray-50">
        <h3 className="text-sm font-medium text-gray-700 mb-3">
          Select Category for All Products
        </h3>
        <div className="max-h-64 overflow-y-auto rounded-lg bg-white border">
          {categoryHierarchy.map((category) => (
            <CategoryItem
              key={category.id}
              category={category}
              onSelect={(categoryId) => {
                dispatch({
                  type: ACTIONS.SET_CATEGORY_ALL,
                  payload: { categoryId },
                });
              }}
            />
          ))}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        {products.map((product, index) => (
          <div key={product.id} className="p-6 border rounded-lg relative">
            <div className="absolute -top-3 left-4 bg-white px-2 text-sm text-gray-600">
              Product {index + 1}
            </div>

            {products.length > 1 && (
              <button
                type="button"
                onClick={() =>
                  dispatch({
                    type: ACTIONS.REMOVE_PRODUCT,
                    payload: { id: product.id },
                  })
                }
                className="absolute top-4 right-4 text-red-500 hover:text-red-700"
                title="Remove product"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            )}

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  value={product.name}
                  onChange={(e) =>
                    dispatch({
                      type: ACTIONS.UPDATE_PRODUCT,
                      payload: {
                        id: product.id,
                        field: "name",
                        value: e.target.value,
                      },
                    })
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                  required
                />
              </div>

              {/* Price Fields */}
              <PriceFields product={product} dispatch={dispatch} />

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Images ({product.images.length}/5)
                  {product.images.length === 0 && (
                    <span className="text-gray-500 ml-2">
                      First uploaded image will be the main image
                    </span>
                  )}
                </label>

                {/* Image grid */}
                {product.images.length > 0 && (
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-4">
                    {product.images.map((image) => (
                      <div key={image.id} className="relative group">
                        <img
                          src={image.url}
                          alt={`Product ${image.id}`}
                          className="w-full h-32 object-cover rounded-lg"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-opacity rounded-lg flex items-center justify-center opacity-0 group-hover:opacity-100">
                          <div className="flex space-x-2">
                            {!image.isMain && (
                              <button
                                type="button"
                                onClick={() =>
                                  handleSetMainImage(product.id, image.id)
                                }
                                className="p-1 bg-blue-500 rounded-full text-white hover:bg-blue-600"
                                title="Set as main image"
                              >
                                <Check className="w-4 h-4" />
                              </button>
                            )}
                            <button
                              type="button"
                              onClick={() =>
                                handleDeleteImage(product.id, image.id)
                              }
                              className="p-1 bg-red-500 rounded-full text-white hover:bg-red-600"
                              title="Delete image"
                            >
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                        {image.isMain && (
                          <span className="absolute top-2 left-2 bg-green-500 text-white px-2 py-1 rounded-md text-xs">
                            Main
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {/* Image upload button */}
                {product.images.length < 5 && (
                  <div className="relative">
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, product.id)}
                      className="hidden"
                      id={`image-upload-${product.id}`}
                      disabled={product.isUploading}
                    />
                    <label
                      htmlFor={`image-upload-${product.id}`}
                      className={`
                        flex items-center justify-center px-4 py-2 border border-gray-300 
                        rounded-md shadow-sm text-sm font-medium text-gray-700 
                        bg-white hover:bg-gray-50 cursor-pointer
                        ${
                          product.isUploading
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }
                      `}
                    >
                      {product.isUploading ? (
                        <>
                          <Loader className="w-4 h-4 mr-2 animate-spin" />
                          Uploading...
                        </>
                      ) : (
                        <>
                          <Upload className="w-4 h-4 mr-2" />
                          Add Images
                        </>
                      )}
                    </label>

                    {product.isUploading && (
                      <div className="mt-2">
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div
                            className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}

        <div className="flex justify-between items-center">
          <button
            type="button"
            onClick={() => dispatch({ type: ACTIONS.ADD_PRODUCT })}
            className="flex items-center gap-2 px-4 py-2 text-primary hover:text-primary/80 transition-colors"
          >
            <Plus className="w-5 h-5" />
            Add Another Product
          </button>

          <button
            type="submit"
            disabled={isSubmitting || !validateProducts()}
            className="flex items-center gap-2 px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? (
              <>
                <Loader className="w-5 h-5 animate-spin" />
                Creating Products...
              </>
            ) : (
              "Create Products"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateProduct;
